.App-logo {
  height: 50vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 40s linear;
  }
}

.App-header {
  background-color: #ffffff;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
  margin-bottom: 10%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.experience {
  display: flex;
  justify-content: center;
}
.resume {
  border: 2px solid black;
  margin-left: 5%;
  margin-right: 5%;
  max-width: 90%;
}

.button-54 {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px,
    5px 5px 0px 0px;
  /* position: relative; */
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-bottom: 10px;
  /* margin: 0;
  position: absolute;
  top: 1%;
  left: 43%; */
  align-content: flex-end;
}

.button-54:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

@media (min-width: 768px) {
  .button-54 {
    padding: 0.25em 0.75em;
  }
}

.projectCard {
  font-family: "Bebas Neue", cursive;
  font-size: 24px;
  margin: 10%;
  display: flex;
  justify-content: space-between;
}

.card {
  /* justify-content: center; */
  /* height: 50%; */
  /* width: 1rem;
  height: 1rem;
} */
  margin: 5px;
  width: 1rm;
}

@media screen and (max-width: 649px) {
  .projectCard {
    font-size: 15px;
  }
}

.cardLink {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#videoTag {
  height: 100%;
  width: 100%;
  float: left;
  top: 0;
  padding: none;
  position: fixed; /* optional depending on what you want to do in your app */
  z-index: -1;
}
#name {
  position: absolute;
  top: 0%;
}

.skills {
  position: absolute;

  bottom: 0;
  right: 0%;
  display: flex;
  flex-wrap: wrap;
}

.skillText {
  margin-right: 5px;
}

.demoPage {
  background-color: aliceblue;
  margin-left: 300px;
}

.linklogo {
  position: relative;
}
/* contain */

#links {
  text-align: right;
}
#email {
  text-align: right;
}
/* // */
.about {
  font-size: 34px;
  position: relative;
  font-family: "Bebas Neue", cursive;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  align-content: center;
  flex-wrap: wrap;
  margin: 25%;
  margin-top: 5%;
  margin-bottom: 5%;
}
@media screen and (max-width: 535px) {
  .about {
    font-size: 18px;
  }
}

.form {
  position: relative;

  font-family: "Bebas Neue", cursive;
  font-size: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 535px) {
  .form {
    font-size: 30px;
  }
}
/* Three image containers (use 25% for four, and 50% for two, etc) */
.column {
  float: left;
  width: 23.33%;
  padding: 15px;
  margin-left: 100px;
  margin-top: 50px;
}

/* Clear floats after image containers */
.row::after {
  content: "";
  clear: both;
  display: table;
}
/* ///////////////////////////////////////////// */

/* text animations /Home */
#typewriter {
  font-family: "Press Start 2P", cursive;
  position: relative;

  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.swapText {
  /* position: static; */
  /* margin-left: 500px; */
  /* margin-top: 250px; */
  font-family: "Press Start 2P", cursive;
  position: relative;
  /* left: 45%;
  top: 50%;
  width: 500px;
  height: 100px;
  margin-left: -100px;
  margin-top: 10px; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 1px;
}

.navigation {
  font-size: 20px;
  padding: 5px 4px;
  /* color: black; */
  /* height: 100vh; */
  text-decoration: none;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  font-family: "Press Start 2P", cursive;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;

  color: #202c2d;
  text-shadow: 0 1px #808d93, -1px 0 #cdd2d5, -1px 2px #808d93, -2px 1px #cdd2d5,
    -2px 3px #808d93, -3px 2px #cdd2d5, -3px 4px #808d93, -4px 3px #cdd2d5,
    -4px 5px #808d93, -5px 4px #cdd2d5, -5px 6px #808d93, -6px 5px #cdd2d5,
    -6px 7px #808d93, -7px 6px #cdd2d5, -7px 8px #808d93, -8px 7px #cdd2d5;
}
.navigation:hover {
  color: yellow;
}

/* page styles */
.page {
  /* position: absolute; */
  top: 100px;
  left: 0;
  right: 0;
}
.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}
.fade-exit {
  opacity: 0; /* leave at 0 or you get an image lag when exiting*/
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

@media (min-width: 600px) {
}
